.bataille-page {
    padding: 50px 0;
    position: relative;
    margin-bottom: 40px;

    .hand {
        opacity: 0;
        transition: opacity .5s 1s;
    }

    &.start {
        &::before {
            opacity: 0;
        }

        .player-a {
            bottom: 0;
        }

        .player-b {
            top: 0;
        }

        .hand {
            opacity: 1;
        }

        .scene::before {
            opacity: 0;
        }
    }

    &::before {
        content: "VS";
        position: absolute;
        font-family: Anton;
        letter-spacing: 2px;
        color: orangered;
        font-size: 40px;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: opacity 1s ease;
    }

    &.player-winner-a .player-a,
    &.player-winner-b .player-b {
        .player-score {
            background-color: orange;
        }

        .crown {
            opacity: 1;
        }
    }

    &.player-winner-a {
        .player-reward {
            scale: 1;
        }
    }

    &.end {
        .player-b {
            top: 366px;
        }

        .player-a {
            bottom: 366px;
        }

        .btn-restart {
            opacity: 1;
            z-index: 1;
        }

        .scene::before {
            opacity: 1;
        }

        .history {
            display: block;
        }

        .player-b, .player-a {
            opacity: .9;
        }
        &.player-winner-b .player-b, &.player-winner-a .player-a {
            opacity: 1;
        }
    }

    .scene {
        width: 808px;
        height: 808px;
        position: relative;
        margin: 0 auto;
        background-color: #e5e5f7;
        background-image: linear-gradient(#dbd4d4 4px, transparent 4px), linear-gradient(to right, #dbd4d4 4px, #eee 4px);
        background-size: 80px 80px;
        border-radius: 4px;
        background-position: -2px -2px;
        border: 4px solid #dbd4d4;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(black, .2);
            transition: opacity 1s;
        }
    }

    .scene.fighting {
        .card-hand {
            bottom: 0 !important;
        }
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

    .score {
        position: absolute;
        z-index: 3;
        width: 100px;
        height: 40px;
        background-color: black;
        color: white;
        text-align: center;
        line-height: 40px;
        font-size: 30px;
        font-family: impact;
        left: -100px;

        &.score-a {
            bottom: 200px;
        }

        &.score-b {
            top: 200px;
        }
    }

    .hand {
        position: absolute;
        width: 100%;
        white-space: nowrap;
        height: 240px;

        .card-hand {
            position: absolute;
            display: inline-block;
            cursor: pointer;
            width: 160px;
            height: 240px;
            bottom: 0;
            left: 0;
            transition: bottom .1s ease;

            &:hover {
                z-index: 2;
                bottom: 20px;
            }
        }

        &.hand-a .card,
        &.hand-b .flip-card {
            transform-origin: left top;
            scale: .8;
            float: none;
        }

        &.hand-b .card-hand:hover {
            bottom: auto;
        }

        &.hand-b .flip-card .flip-card-inner {
            transition: transform .5s;
        }

        .card-hand-moving {
            transition: left .5s ease, bottom .5s ease;
            z-index: 2;
        }

        &.hand-a .card-hand-moving {
            left: 200px !important;
            bottom: 240px + 40px;
        }

        &.hand-b .card-hand-moving {
            left: 440px !important;
            bottom: -280px;
        }

        @for $i from 1 through 5 {
            &.hand-#{$i} {
                $marge: 5 - $i;

                @for $v from 1 through 5 {
                    .card-hand:nth-child(#{$v}) {
                        left: ($v * 160px) - 160px + $marge * 80px;
                    }
                }
            }
        }
    }

    .hand-a {
        bottom: 0;
    }

    .hand-b {
        top: 0;
    }

    .battlefield {
        height: 320px;
        position: relative;
        top: 240px;

        &.no-battle {
            display: none;
        }

        .battlefield-card {
            width: 160px;
            height: 240px;
            position: absolute;
            top: 40px;
            left: 200px;

            .card {
                transform-origin: left top;
                scale: .8;
                float: none;
            }
        }

        .battlefield-b {
            left: 440px;

            &.winning {
                animation: winning-b 1s;
            }

            &.winning-2 {
                animation: winning-b-2 1s;
            }

            &.winning-3 {
                animation: winning-b-3 1s;
            }

            &.losing {
                animation: losing-b 1s forwards;
            }

            &.losing-2 {
                animation: losing-b-2 1s forwards;
            }

            &.losing-3 {
                animation: losing-b-3 1s forwards;
            }

            &.equality {
                animation: equality-b 1s forwards;
            }
        }

        .battlefield-a {
            &.winning {
                animation: winning-a 1s;
            }

            &.winning-2 {
                animation: winning-a-2 1s;
            }

            &.winning-3 {
                animation: winning-a-3 1s;
            }

            &.losing {
                animation: losing-a 1s forwards;
            }

            &.losing-2 {
                animation: losing-a-2 1s forwards;
            }

            &.losing-3 {
                animation: losing-a-3 1s forwards;
            }

            &.equality {
                animation: equality-a 1s forwards;
            }
        }
    }

    @keyframes winning-a {
        0% {
            left: 200px;
        }

        20% {
            left: 240px;
        }

        40% {
            left: 200px;
        }

        60% {
            top: 40px;
        }

        70% {
            top: 20px;
        }

        80% {
            top: 40px;
        }

        90% {
            top: 20px;
        }

        100% {
            top: 40px;
        }
    }

    @keyframes winning-a-2 {
        0% {
            left: 200px;
        }

        20% {
            left: 240px;
        }

        40% {
            left: 200px;
            transform: rotate(0);
        }

        60% {
            transform: rotate(10deg);
        }

        70% {
            transform: rotate(-10deg);
        }

        80% {
            transform: rotate(10deg);
        }

        90% {
            transform: rotate(-10deg);
        }

        100% {
            transform: rotate(0);
        }
    }

    @keyframes winning-a-3 {
        0% {
            left: 200px;
        }

        20% {
            left: 240px;
        }

        40% {
            left: 200px;
            transform: rotate(0);
            scale: 1;
        }

        60% {
            transform: rotate(10deg);
            scale: 1.2;
        }

        70% {
            transform: rotate(-10deg);
            scale: 1;
        }

        80% {
            transform: rotate(10deg);
            scale: 1.2;
        }

        90% {
            transform: rotate(-10deg);
            scale: 1;
        }

        100% {
            transform: rotate(0);
            scale: 1;
        }
    }

    @keyframes equality-a {
        0% {
            left: 200px;
        }

        20% {
            left: 240px;
        }

        25% {
            left: 235px;
        }

        30% {
            left: 240px;
        }

        40% {
            left: 200px;
            transform: rotate(0);
        }

        70% {
            transform: rotate(-5deg);
        }

        100% {}
    }

    @keyframes losing-a {
        0% {
            left: 200px;
        }

        20% {
            left: 240px;
        }

        40% {
            left: 200px;
        }

        60% {
            opacity: 1;
        }

        70% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes losing-a-2 {
        0% {
            left: 200px;
        }

        20% {
            left: 240px;
        }

        40% {
            transform: rotate(0);
            left: 200px;
        }

        100% {
            transform: rotate(360deg);
            opacity: 0;
        }
    }

    @keyframes losing-a-3 {
        0% {
            left: 200px;
        }

        20% {
            left: 240px;
        }

        40% {
            transform: rotate(0);
            left: 0;
        }

        100% {
            transform: rotate(360deg);
            scale: 1.2;
            left: 0;
            opacity: 0;
        }
    }

    @keyframes equality-b {
        0% {
            left: 440px;
        }

        20% {
            left: 400px;
        }

        25% {
            left: 405px;
        }

        30% {
            left: 400px;
        }

        40% {
            left: 440px;
            transform: rotate(0);
        }

        70% {
            transform: rotate(5deg);
        }

        100% {}
    }

    @keyframes losing-b {
        0% {
            left: 440px;
        }

        20% {
            left: 400px;
        }

        40% {
            left: 440px;
        }

        60% {
            opacity: 1;
        }

        70% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes losing-b-2 {
        0% {
            left: 440px;
        }

        20% {
            left: 400px;
        }

        40% {
            transform: rotate(0);
            left: 440px;
        }

        100% {
            transform: rotate(360deg);
            opacity: 0;
        }
    }

    @keyframes losing-b-3 {
        0% {
            left: 440px;
        }

        20% {
            left: 400px;
        }

        40% {
            transform: rotate(0);
            left: 640px;
        }

        100% {
            transform: rotate(360deg);
            scale: 1.2;
            left: 640px;
            opacity: 0;
        }
    }

    @keyframes winning-b {
        0% {
            left: 440px;
        }

        20% {
            left: 400px;
        }

        40% {
            left: 440px;
        }

        60% {
            top: 40px;
        }

        70% {
            top: 20px;
        }

        80% {
            top: 40px;
        }

        90% {
            top: 20px;
        }

        100% {
            top: 40px;
        }
    }

    @keyframes winning-b-2 {
        0% {
            left: 440px;
        }

        20% {
            left: 400px;
        }

        40% {
            left: 440px;
            transform: rotate(0);
        }

        60% {
            transform: rotate(10deg);
        }

        70% {
            transform: rotate(-10deg);
        }

        80% {
            transform: rotate(10deg);
        }

        90% {
            transform: rotate(-10deg);
        }

        100% {
            transform: rotate(0);
        }
    }

    @keyframes winning-b-3 {
        0% {
            left: 440px;
        }

        20% {
            left: 400px;
        }

        40% {
            left: 440px;
            transform: rotate(0);
            scale: 1;
        }

        60% {
            transform: rotate(10deg);
            scale: 1.2;
        }

        70% {
            transform: rotate(-10deg);
            scale: 1;
        }

        80% {
            transform: rotate(10deg);
            scale: 1.2;
        }

        90% {
            transform: rotate(-10deg);
            scale: 1;
        }

        100% {
            transform: rotate(0);
            scale: 1;
        }
    }
}

@keyframes crownScale {
    from {
        scale: 1;
    }

    to {
        scale: 1.1;
    }
}

.player {
    width: 300px;
    height: 50px;
    background-color: #1e272e;
    padding: 4px;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: Anton;
    letter-spacing: 1px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    transition: top 1s, bottom 1s;

    &.player-b {
        top: 366px;
    }

    &.player-a {
        bottom: 366px;
    }

    .crown {
        position: absolute;
        color: orange;
        left: -15px;
        transform: translateX(-50%);
        top: -15px;
        transform: rotate(-40deg);
        transform-origin: center center;
        animation: crownScale 2s infinite alternate-reverse;
        opacity: 0;
        transition: opacity .5s 1s;
    }

    &.player-bot::before {
        content: "bot";
        position: absolute;
        background-color: orangered;
        font-size: 6px;
        padding: 2px 10px;
        bottom: 0px;
        left: 50%;
        font-family: Helvetica;
        transform: translateX(-50%);
    }

    img,
    .player-score {
        width: 42px;
        height: 42px;
        object-fit: cover;
        border-radius: 100%;
        display: block;
        background-color: white;
    }

    span {
        line-height: 42px;
        text-align: center;
    }

    .player-score {
        transition: background .5s 1s;
        background-color: #3c40c6;
    }

    .player-reward {
        position: absolute;
        background-color: orange;
        border-radius: 4px;
        width: 50px;
        height: 20px;
        line-height: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        font-family: Arial;
        bottom: -10px;
        scale: 0;
        transition: scale .5s 2s;
    }
}

.btn-restart {
    height: 50px;
    line-height: 46px;
    width: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0;
    transition: opacity .5s 1s;
}

.history {
    left: 0;
    top: 0;
    position: absolute;
    z-index: 10;
    display: none;
    width: 156px;
    left: 50%;
    margin-left: -398px;
    top: 56px;

    ul {
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        overflow: hidden;
        display: block;
        background-color: #1e272e;
        position: relative;
        height: 0;
        overflow: hidden;
        border: 2px solid black;
        opacity: 0;
        transition: height .2s ease, opacity 0s .2s;

        &::before {
            content: "";
            width: 50%;
            height: 100%;
            background-color: #3c40c6;
            position: absolute;
        }
    }

    &.open ul {
        height: 476px;
        opacity: 1;
        transition: height .2s ease;
    }

    button {
        position: absolute;
        z-index: 1;
        width: 20px;
        height: 20px;
        border: 0;
        background-color: orange;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        border-bottom: 2px solid #cc8400;

        svg {
            width: 10px;
        }
    }


    .history-players {
        line-height: 76px;
        position: relative;

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 100%;
            top: 5px;
            position: relative;
            border: 2px solid;
        }

        .history-score {
            top: 30px;

            &.history-a b:first-child {
                background-color: orange;
            }

            &.history-b b:last-child {
                background-color: orange;
            }
        }

        .history-players-name {
            position: absolute;
            bottom: 4px;
            z-index: 1;
            line-height: 1;
            display: flex;
            width: 100%;

            b {
                display: block;
                text-align: center;
                width: 50%;
                color: white;
                font-size: 8px;
                line-height: 16px;
                background-color: #3c40c6;

                &:first-child {
                    background-color: #1e272e;
                }
            }
        }
    }

    li {
        display: flex;
        height: 76px;
        justify-content: space-around;
        margin-bottom: 4px;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .history-line {
        position: relative;
    }

    .history-score {
        position: absolute;
        width: 30px;
        left: 50%;
        top: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        display: flex;
        border-radius: 4px;
        overflow: hidden;

        b {
            background-color: #1e272e;
            line-height: 20px;
            width: 15px;
            height: 20px;
            display: block;
            color: white;

            &:last-child {
                background-color: #3c40c6;
            }
        }
    }

    .history-card {
        height: 40px;
        width: 40px;
        background-color: black;
        border: 2px solid black;
        border-radius: 2px;
        position: relative;

        &.lose {
            &::before {
                content: "✕";
                color: orangered;
                position: absolute;
                font-size: 38px;
                width: 100%;
                text-align: center;
                top: 0;
            }
        }

        img {
            width: 36px;
            height: 36px;
            object-fit: cover;
        }

        b {
            color: white;
            text-align: center;
            width: 100%;
            display: block;
            font-size: 12px;
            line-height: 18px;
            position: relative;

            sup {
                font-size: 6px;
                width: 7px;
                background-color: gray;
                display: block;
                height: 7px;
                line-height: 7px;
                position: absolute;
                bottom: 0;
                right: 0;
                border-radius: 2px;
            }
        }
    }
}