.flip-card {
    width: 200px;
    height: 300px;
    perspective: 1000px;
    left: 0;
    top: 0;
    transition: left 1s ease, top 1s ease;
    &.flip-card-1 {
        transition: left 1s 1s ease;
        .flip-card-inner {
            transition: transform 0.8s 2s;
        }
    }
    /* Remove this if you don't want the 3D effect */
    &.reveal {
        left: 400px;
        .flip-card-inner {
            transform: rotateY(180deg);
        }
    }
}

@for $i from 1 through 10 {
    .flip-card.flip-card-#{$i}.reveal {
        left: 300px + ($i * 10px);
    }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s 1s;
    transform-style: preserve-3d;
}

.flip-card {
    /* Position the front and back side */
    .card,
    .card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        /* Safari */
        backface-visibility: hidden;
        transform: rotateX(0deg);
    }

    .card {
        transform: rotateY(180deg);
    }

    .card-back {
        z-index: 1;
        position: absolute;
        background-color: white;
        border: 1px solid black;
        padding: 10px;
        overflow: hidden;
        border-radius: 10px;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            background-color: #e5e5f7;
            opacity: 0.8;
            background: linear-gradient(135deg, #444cf755 25%, transparent 25%) -6px
                    0/ 12px 12px,
                linear-gradient(225deg, #444cf7 25%, transparent 25%) -6px 0/
                    12px 12px,
                linear-gradient(315deg, #444cf755 25%, transparent 25%) 0px 0/ 12px
                    12px,
                linear-gradient(45deg, #444cf7 25%, #e5e5f7 25%) 0px 0/ 12px 12px;
            background-size: 3em 3em;
            background-color: #e3e3e3;
            opacity: 1;
            display: block;
            border-radius: 10px;
            cursor: help;
        }

        .card-rarity {
            position: absolute;
            color: white;
            background-color: black;
            height: 72px;
            width: 72px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            line-height: 72px;
            clip-path: polygon(
                30% 0%,
                70% 0%,
                100% 30%,
                100% 70%,
                70% 100%,
                30% 100%,
                0% 70%,
                0% 30%
            );
            text-align: center;

            &::before,
            &::after {
                content: "";
                position: absolute;
                display: block;
                clip-path: polygon(
                    30% 0%,
                    70% 0%,
                    100% 30%,
                    100% 70%,
                    70% 100%,
                    30% 100%,
                    0% 70%,
                    0% 30%
                );
            }

            &::before {
                width: 70px;
                height: 70px;
                top: 1px;
                left: 1px;
                background-color: #fff;
            }

            &::after {
                width: 68px;
                height: 68px;
                top: 2px;
                left: 2px;
                background-color: #000;
            }

            .rarity {
                position: relative;
                z-index: 1;
                font-size: 30px;
                vertical-align: middle;
                text-transform: uppercase;
                font-family: Roboto;
            }
        }
    }
}
