.form-new-family {
    padding-left: 20px;
}

.list-families {
    width: calc(100% - 40px);
    margin: 0 auto;
    li {
        border-bottom: 1px solid gray;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        button {
            cursor: pointer;
        }
    }
}

.page-title-families {
    display: flex;
    justify-content: space-between;
}