@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Montserrat&family=Anton&family=Roboto&family=Silkscreen&display=swap');

.wrap {
    font-family: Arial;
    min-width: 800px;
}

a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.page-title {
    position: relative;
    display: inline-block;
    padding: 0 50px 0 100px;
    height: 30px;
    background-color: #1e272e;
    color: white;
    line-height: 30px;
    margin-bottom: 40px;

    &::before {
        content: "";
        position: absolute;
        right: -30px;
        display: inline-block;
        height: 0;
        width: 0;
        border-top: 30px solid #1e272e;
        border-right: 30px solid transparent;
    }

    &:first-child {
        margin-top: 0;
    }

    .btn-cancel {
        color: orange;
        cursor: pointer;
        background-color: transparent;
        border: 0;
    }

    svg {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-color: red;
        padding: 10px;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        background-color: #3c40c6;
        border: 4px solid #1e272e;
    }
}

.empty-section {
    text-align: center;
}

.filter-cards {
    button {
        height: 30px;
        margin-left: 10px;
        background-color: black;
        color: white;
        border: none;
        border-radius: 2px;
        cursor: pointer;

        &.active,
        &:hover {
            background-color: #c0392b;
        }
    }
}

.form {
    width: 300px;
    text-align: center;
    margin: 0 auto;
    background-color: #1e272e;
    border-radius: 20px;
    overflow: hidden;
    padding: 40px;

    h1 {
        padding: 20px 0;
        background-color: orange;
        text-transform: uppercase;
        margin-bottom: 20px;
        color: white;
    }

    .form-row-error {
        width: 200px;
        text-align: center;
        font-size: 12px;
        color: orangered;
        margin: 0 auto 20px;
    }

    input {
        border-radius: 20px !important;
        padding: 0 20px !important;
    }
    .btn-general {
        width: 200px;
    }
}

@keyframes rotatingChecking {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.checking {
    text-align: center;

    span {
        display: inline-block;
        -webkit-animation: rotatingChecking 1s linear infinite;
        animation: rotatingChecking 1s linear infinite;
    }
}

.content {
    padding-top: 40px;
    width: 100%;
    min-width: 800px;
    margin: 0 auto;
}

$color: orange;

.btn {
    text-align: center;
    text-decoration: none;
    color: $color;
    border: 2px solid $color;
    font-size: 20px;
    display: inline-block;
    border-radius: 0.3em;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    position: relative;
    height: 40px;
    font-family: Anton;
    letter-spacing: 2px;
    text-transform: uppercase;

    &:before {
        content: "";
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(0);
        transition: none;
    }

    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    cursor: pointer;

    &:hover {
        &:before {
            transform: skewX(-45deg) translateX(1500%);
            transition: all 0.5s ease-in-out;
        }
    }
}

.confirm-modal {
    position: fixed;
    background-color: rgba(#1e272e, .9);
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.modal-open {
        display: block;
    }

    .confirm-modal-box {
        position: absolute;
        z-index: 2;
        width: 400px;
        padding: 20px;
        background-color: #eee;
        border-radius: 10px;
        box-shadow: black 4px 4px 10px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    h1 {
        font-family: Anton;
        margin-bottom: 20px;
        letter-spacing: 1px;
    }

    p {
        margin-bottom: 20px;
    }

    .buttons {
        text-align: right;

        button {
            border: 0;
            font-family: Anton;
            text-transform: uppercase;
            margin-left: 20px;
            cursor: pointer;
            letter-spacing: 1px;
        }
    }
}

.bye {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    z-index: 30;
    background-color: #1e272e;
    h1 {
        font-family: Anton;
        font-size: 100px;
        letter-spacing: 10px;
        position: absolute;
        color: white;
        text-align: center;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    &::before {
        content: "";
        position: absolute;
        background-color: rgba(black, .4);
    }

    img,
    &::before {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.btn-general {
    border: 0;
    cursor: pointer;
    display: inline-block;
    background-color: #3c40c6;
    height: 30px;
    line-height: 28px;
    color: white;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 0 20px;
    border-bottom: 3px solid rgba(black, .4);
    transition: all .1s;
    &.light {
        background-color: orange;
    }
    svg {
        vertical-align: baseline;
    }
    &:hover {
        line-height: 30px;
        border-width: 0;
    }
}

.welcome {
    width: 800px;
    margin: 0 auto;
    line-height: 22px;
    i {
        width: 100%;
        display: block;
        text-align: right;
        font-style: italic;
    }
}