.chat-box {
    width: 300px;
    height: 400px;
    position: fixed; 
    border-bottom: 0;
    bottom: 10px;
    right: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 9px #ccc;
    overflow: hidden;
    transition: height .5s, width .5s;
    background-color: #fff;
    z-index: 11;
    &.reduce-chat {
        width: 30px;
        height: 30px;
        .chat-reduce-box {
            width: 30px;
            height: 30px;
        }
        button {
            color: white;
        }
    }
}
.has-new-message .chat-reduce-box {
    animation: blinkingBackground 2s infinite;
}
@keyframes blinkingBackground {
    0% { background-color: #3c40c6; }
    50% { background-color: orangered; }
    100% { background-color: #3c40c6; }
}

.chat-reduce-box {
    border: 0;
    transition: height .5s .2s, width .5s .2s;
    background-color: #3c40c6;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    text-align: center;
    line-height: 30px;
    padding: 0;
    cursor: pointer;
}

.chat-box-top {
    height: 40px;
    width: 100%;
    line-height: 40px;
    padding: 0 20px;
    background-color: #3c40c6;
    color: white;
    display: flex;
    justify-content: space-between;
    button {
        color: white;
        border: none;
        background: transparent;
        cursor: pointer;
    }
}
.chat-box-content {
    height: calc(100% - 80px);
    overflow: auto;
    padding-top: 21px;
}
.chat-box-bottom {
    position: absolute;
    bottom: 0;
    height: 40px;
    padding-top: 5px;
    width: 100%;
    border-top: 1px solid #ccc;
    background-color: white;
    input {
        width: calc(100% - 30px);
        border: 0;
        padding: 0 20px;
        outline: none;
        &:active {
            border: 0;
        }
    }
    button {
        color: #3c40c6;
        height: 30px;
        width: 30px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }
}

.message {
    .message-info {
        font-size: 10px;
        position: absolute;
        left: 0;
        top: -20px;
        background-color: #3c40c6;
        color: white;
        padding: 4px;
        border-radius: 2px;
        opacity: 0;
        white-space: nowrap;
        pointer-events: none;
        transition: opacity .2s;
    }
    .chat-photo:hover .message-info {
        opacity: 1;
    }
    position: relative;
    margin-bottom: 21px;
    padding-right: 20px;
    .chat-photo {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        img {
            object-fit: cover;
            position: absolute;
            z-index: 1;
            left: 0;
        }
        position: absolute;
        left: 20px;
        background-color: black;
        &::before, &::after {
            content: "";
            position: absolute;
            background-color: #fff;
            left: 50%;
            border-radius: 100%;
        }
        &::before {
            width: 10px;
            height: 10px;
            bottom: -5px;
            margin-left: -5px;
        }
        &::after {
            width: 6px;
            height: 6px;
            bottom: 5px;
            margin-left: -3px;
        }
    }
    p {
        margin-left: 50px;
        background-color: rgba(#3c40c6, .1);
        width: auto;
        display: inline-block;
        padding: 10px;
        border-radius: 4px;
        min-height: 36px;
    }
}