.navbar {
    &.navbar-admin {
        background-color: orange;
    }
    height: 50px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 0 10px;
    font-family: Roboto;
    background-color: #1e272e;
    &.navbar-authenticated {
        justify-content: space-between;
        h1 {
            margin-right: 0;
        }
    }
    h1 {
        margin-right: auto;
    }
    h1 a {
        display: block;
        width: 30px;
        height: 30px;
        background-color: #3c40c6;
        margin: 10px;
        border-radius: 4px;
        text-align: center;
        line-height: 30px;
        position: relative;
        transition: all .1s;
        &:hover {
            span, &::after {
                transform: rotate(-20deg);
            }
            &::before {
                transform: rotate(20deg);
            }
        }
        span {
            transition: all .1s;
            position: relative;
            z-index: 2;
            color: #1e272e;
            font-size: 8px;
            display: block;
            transform: rotate(20deg);
            transform-origin: center center;
        }
        &::before, &::after {
            transition: all .1s;
            content: "";
            background-color: #fff;
            width: 10px;
            border-radius: 1px;
            position: absolute;
            height: 18px;
            left: 50%;
            margin-left: -5px;
            top: 6px;
            z-index: 1;
            transform-origin: center center;
        }
        &::after {
            transform: rotate(20deg);
        }
        &::before {
            transform: rotate(-20deg);
            background-color: #1e272e;
        }
    }

    .navlink,
    .navuser,
    button,.btn-add-family {
        display: block;
        height: 30px;
        line-height: 30px;
        margin: 10px;
        padding: 0 10px;
        border-radius: 20px;
        font-size: 12px;
        color: white;
        transition: background .1s;
        border: none;
        background-color: transparent;
        &:hover,
        &.active {
            background-color: #3c40c6;
        }
    }
    @keyframes highlight {
        from {
            background-color: #3c40c6;
        } to {
            background-color: orange;
        }
    }
    .header-money, .header-money:hover {
        background-color: orange;
        &.highlight {
            animation: highlight 1s;
        }
    }
    .header-money {
        margin-left: 10px;
    }
    .navuser-wrap {
        display: flex;
    }
    .navuser {
        height: 30px;
        border-radius: 20px;
        padding: 0 10px 0 5px;
        border: 1px solid #3c40c6;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3c40c6;
        display: flex;
        margin-right: 0;
        &:hover {
            background-color: #3c40c6;
        }
        .photo {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            object-fit: cover;
            margin-right: 10px;
        }
        .no-photo {
            background-color: #1e272e;
            overflow: hidden;
            position: relative;
            &::before, &::after {
                content: "";
                position: absolute;
                background-color: #fff;
                left: 50%;
                border-radius: 100%;
            }
            &::before {
                width: 14px;
                height: 14px;
                margin-left: -7px;
                bottom: -7px;
            }
            &::after {
                width: 8px;
                height: 8px;
                margin-left: -4px;
                bottom: 7px;
            }
        }
    }
}