.modal-wrap {
    display: none;
}
.modal {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, .8);
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 20;
    .close {
        position: fixed;
        top: 20px;
        right: 20px;
        border: none;
        height: 30px;
        width: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 100%;
        z-index: 1;
    }
    .modal-content {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
    }
    h1 {
        width: 100%;
        background-color: orange;
        color: white;
        font-family: Arial;
        padding: 10px 20px;
    }
    .modal-body {
        padding: 20px;
        input {
            width: 100%;
            padding: 0 10px;
        }
        button {
            border: none;
            padding: 10px 20px;
            background-color: orange;
            color: white;
        }
    }
}