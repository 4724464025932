@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

.decks-page {
    width: 800px;
    margin: 0 auto;
    padding-bottom: 330px;
}
.user-deck-wrap {
    z-index: 2;
    position: fixed;
    bottom: 0;
    width: 800px;
    left: 50%;
    margin-left: -400px;
    background-color: rgba(#3c40c6, 0.8);
    border-radius: 10px;
    box-shadow: #111 0 0 10px;
    transition: background 0.2s ease;
    &.completed {
        background-color: rgba(#1e272e, 0.95);
        .btn-bataille {
            height: 40px;
            border-bottom: 4px solid rgba(black, 0.2);
        }
    }
    h1 {
        text-align: center;
        font-family: Anton;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 50px;
        color: white;
    }
    .btn-bataille {
        position: relative;
        bottom: auto;
        left: auto;
        width: 100%;
        display: block;
        opacity: 1;
        box-shadow: none;
        font-family: Anton;
        text-transform: uppercase;
        letter-spacing: 2px;
        height: 0;
        border: 0;
        svg {
            vertical-align: baseline;
        }
    }
}
.user-deck {
    height: 240px;

    display: flex;
    z-index: 2;
    @for $i from 1 through 5 {
        .deck-card:nth-child(#{$i})::before {
            content: "#{$i}";
        }
    }
    .deck-card {
        height: 100%;
        width: 160px;
        border-radius: 8px;
        position: relative;
        &::before {
            width: 100%;
            height: 100%;
            position: absolute;
            border: 8px solid #1e272e;
            border-radius: 8px;
            text-align: center;
            line-height: 220px;
            font-family: "Anton", sans-serif;
            font-size: 40px;
            color: white;
        }
        &::after {
            content: "";
            width: 80px;
            height: 80px;
            border-radius: 100%;
            border: 8px solid white;
            top: 50%;
            left: 50%;
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
        }
        .card {
            float: none;
            scale: 0.8;
            transform-origin: 0 0;
            z-index: 2;
            position: relative;
        }
    }
}

.user-cards {
    display: flex;
    width: 800px;
    flex-wrap: wrap;
}
.user-card {
    position: relative;
    width: 160px;
    height: 240px;
    cursor: pointer;
    .card {
        pointer-events: none;
        transform-origin: top left;
        float: none;
        scale: 0.8;
    }
    .position-card {
        position: absolute;
        z-index: 1;
        width: 100px;
        height: 100px;
        border: 10px solid;
        top: 50%;
        left: 50%;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        text-align: center;
        line-height: 80px;
        font-size: 50px;
        color: white;
        font-family: "Anton", sans-serif;
        z-index: 2;
    }
    &.selected-card {
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(#1e272e, 0.5);
            z-index: 1;
            border-radius: 10px;
        }
    }
}

.btn-remove-deck {
    position: absolute;
    z-index: 3;
    border: 0;
    color: white;
    background-color: orangered;
    cursor: pointer;
    border-radius: 2px;
    height: 20px;
    line-height: 18px;
    border-bottom: 2px solid rgba(black, 0.2);
    &:hover {
        border-width: 0;
        line-height: 20px;
    }
}

.btn-bataille {
    position: fixed;
    bottom: 20px;
    left: 20px;
    color: white;
    background-color: orangered;
    width: 40px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    border-radius: 4px;
    border-bottom: 4px solid rgba(black, 0.2);
    transition: all 0.2s ease;
    box-shadow: black 2px 2px 4px;
    opacity: 0;
    &:hover {
        border-width: 0 !important;
        line-height: 40px;
        box-shadow: black 0 0 0;
    }
    &.show {
        opacity: 1;
    }
}

.filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    select {
        border: 3px solid #1e272e;
        border-radius: 2px;
        padding: 0 10px;
    }
    button {
        background-color: #1e272e;
        color: white;
        border: 0;
        border-radius: 2px;
        margin-left: 10px;
        cursor: pointer;
        &:active {
            background-color: #3c40c6;
        }
    }
}
