.cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 20px;
    gap: 40px;
}

.card-wrap {
    position: relative;
    .card-actions {
        position: absolute;
        width: 100%;
        display: none;
        z-index: 1;
        padding-top: 20px;
        bottom: 0;
    }
    &:hover .card-actions {
        display: block;
        text-align: center;
    }
    a {
        z-index: 1;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        background-color: #f39c12;
        top: 10px;
        width: calc(100% - 40px);
        color: white;
        margin-bottom: 20px;
        &:hover {
            background-color: black;
        }
    }
}

.filter-cards {
    margin: 0 20px 20px;
}