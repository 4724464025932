@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,700;1,700&display=swap');

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.card:hover {
  animation-duration: 2s;
}

.card {
  float: left;
  overflow: hidden;
  width: 200px;
  height: 300px;
  border: 1px solid black;
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg, yellow 0%, yellow 25%, yellow 51%, #ff357f 100%);
  -webkit-animation: AnimateBG 20s ease infinite;
  animation: AnimateBG 20s ease infinite;
  border-radius: 10px;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
}

.card-1 {
  background-image: linear-gradient(-45deg, #4ca007 0%, #4ca007 25%, #4ca007 51%, white 100%);
}

.card-2 {
  background-image: linear-gradient(-45deg, #2980b9 0%, #2980b9 25%, #2980b9 51%, white 100%);
}

.card-3 {

  background-image: linear-gradient(-45deg, #7f1d83 0%, #7f1d83 25%, #7f1d83 51%, white 100%);
}

.card-4 {
  background-image: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

.card-5 {
  background-image: linear-gradient(45deg, #ff0000, #00f0f0, #00ff00, #0000ff, #ff0000, #00f0f0, #00ff00, #0000ff, #f00f0f);
}

.card-proba {
  position: absolute;
  z-index: 2;
  background-color: black;
  color: white;
  padding: 2px;
}

@keyframes scaleNew {
  from {
    scale: 1;
  } to {
    scale: 1.2;
  }
  
}
.card-new {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  transform: rotate(-20deg);
  font-family: "Bungee Spice";
  font-size: 20px;
  text-shadow: #000 4px 4px;
  animation: scaleNew 1s ease infinite alternate-reverse;
}

.card-rarity {
  position: absolute;
  color: white;
  background-color: black;
  height: 32px;
  width: 32px;
  right: -2px;
  top: -2px;
  line-height: 32px;
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  text-align: center;
  line-height: 32px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  &::before {
    width: 30px;
    height: 30px;
    top: 1px;
    left: 1px;
    background-color: #fff;
  }

  &::after {
    width: 28px;
    height: 28px;
    top: 2px;
    left: 2px;
    background-color: #000;
  }

  .rarity {
    position: relative;
    z-index: 1;
    font-size: 12px;
    vertical-align: middle;
    text-transform: uppercase;
  }
}

.card-content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 2px solid black;
  position: relative;
}

.card-image {
  width: 100%;

  img {
    width: 100%;
    height: 216px;
    object-fit: cover;
  }
}

.card-bottom {
  width: 100%;
  border-top: 2px solid;
  height: 60px;
  position: absolute;
  bottom: 0;
  background-color: #d7c103;

  .card-character-name,
  .card-attack-name {
    position: absolute;
    background-color: black;
    color: white;
    font-size: 8px;
    letter-spacing: 1px;
    height: 16px;
    text-transform: uppercase;
    line-height: 16px;
    padding: 0 30px;
    transform: rotate(-10deg);
    left: -25px;
    top: -40px;
    transform-origin: top left;

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      height: 0;
      width: 0;
      border-top: 15px solid black;
      border-right: 15px solid transparent;
      right: -15px;
    }
  }

  .card-attack-name {
    background-color: red;
    top: -24px;

    &::before {
      border-top: 15px solid red;
    }
  }

  span {
    font-family: Roboto;
    font-style: italic;
    font-size: 16px;
    display: block;
    letter-spacing: 1px;
    padding: 8px 10px;
  }

  .card-power {
    position: absolute;
    right: 10px;
    color: red;
    font-size: 50px;
    -webkit-text-stroke: 2px black;
    font-family: 'Anton', sans-serif;
    top: -20px;
    letter-spacing: 2px;
  }

  .card-subpower {
    background: #798496;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 24px;
    border-top: 2px solid;

    ul {
      clear: both;
    }

    li {
      float: left;
      width: 24px;
      text-align: center;
      line-height: 22px;
      border-left: 1px solid black;
      color: white;

      &.subpower-active {
        background-color: #5e8141;
      }

      &:first-child {
        border: none;
      }

      &:last-child {
        width: 30px;
      }
    }
  }
}

@keyframes bgSubPowerBlink {
  from {
    background-color: orange;
  } to {
    background-color: #798496;
  }
}
.card-opening {
  .subpower-active {
    animation: bgSubPowerBlink 1s infinite alternate-reverse;
    &:first-child {
      animation: none;
    }
  }
}