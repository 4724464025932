.notifications-container {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 20;
}

.notification {
    padding: 4px 12px;
    background-color: #3c40c6;
    margin-top: 10px;
    font-family: Arial;
    color: white;
    box-shadow: #000 2px 2px 4px;
    &:hover {
        background-color: rgb(0, 0, 0);
    }
    button {
        border: none;
        margin-left: 10px;
        background-color: transparent;
        color: white;
        cursor: pointer;
    }
}