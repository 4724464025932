@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Silkscreen&display=swap');

.pack-page {
    width: 800px;
    margin: 0 auto;
    height: 630px;
    margin-bottom: 20px;
}

@keyframes scissor {
    from {
        top: 10px;
    }
    to {
        top: 300px;
    }
}

@keyframes giggling {
    0%, 50% {
        transform: rotate(0);
    }
    80% {
        transform: rotate(2deg);
    }
    90% {
        transform: rotate(-2deg);
    }
    100% {
        transform: rotate(0);
    }
}

.pack-to-open {
    transform-origin: bottom center;
    animation: giggling 2s linear infinite;
    &.pack-open, &:hover {
        animation: none;
    }
}
.pack-wrap {
    position: relative;
    width: 200px;
    height: 350px;
    cursor: pointer;
    z-index: 11;
    &::before {
        display: none;
        content: "";
        position: absolute;
        height: 340px;
        z-index: 30;
        right: 47px;
        top: 5px;
        border-right: 6px dashed black;
        animation: blink 1s infinite;
    }
    &::after {
        display: none;
        content: "✂";
        position: absolute;
        transform: rotate(-90deg);
        right: 30px;
        font-size: 40px;
        z-index: 31;
        color: white;
        animation: scissor 1s linear alternate infinite;
    }
}

.pack-wrap {
    &::before, &::after {
        display: block;
        
    }
}

.pack-wrap.shop-mode:hover {
    &::before, &::after {
        display: none;
        
    }
}

.pack-open-ended {
    .pack-left {
        left: -40px;
        opacity: 0;
        height: 0;
    }
    .flip-card {
        transition: left 1s ease 1s, top 1s ease 1s !important;
    }
    @for $i from 1 through 4 {
        .flip-card-#{$i} {
            left: ($i - 1) * 200px !important;
        }
    }
    @for $i from 5 through 8 {
        .flip-card-#{$i} {
            left: ($i - 5) * 200px !important;
            top: 300px !important;
        }
    }
}


@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.open-cards {
    position: absolute;
    top: 26px;

    .flip-card {
        position: absolute;
        left: 0;
    }
}

.pack-open {
    &::before, &:hover::before, &::after, &:hover::after {
        display: none;
    }

    .pack-right {
        right: -300px;
        transform: rotate(30deg);
        opacity: 0;
        height: 0;
    }
}

.pack-solo {
    position: relative;
    overflow: hidden;
    box-shadow: #000 4px 4px 25px;
    width: 200px;
    border-radius: 2px;
    .opener {
        left: 150px;
        background-color: rgba(black, .8);
    }
}

.pack-right {
    height: 350px;
    width: 50px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1;
    transition: transform 1s ease, right 1s ease, opacity 500ms ease 500ms, height 2s ease 2s;
    transform: rotate(0);
    opacity: 1;
    z-index: 12;

    .pack-body {
        top: 0;
        left: 0;
        transform: translateX(-150px);
    }

    .opener {
        left: -5px;
        right: auto;
        z-index: 2;
    }
}


.pack-left {
    height: 350px;
    width: 150px;
    position: relative;
    overflow: hidden;
    z-index: 11;
    left: 0;
    transition: left 1s ease, opacity 500ms ease 500ms, height 2s ease 2s;
    .opener {
        right: -5px;
    }
}

.pack-body {
    width: 200px;
    height: 350px;
    border: 1px solid;
    border-radius: 2px;
    background: linear-gradient(45deg,
            #ccc 5%,
            #fff 10%,
            #ccc 30%,
            #ddd 50%,
            #ccc 70%,
            #fff 80%,
            #ccc 95%);
    overflow: hidden;
}

.opener {
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    top: -5px;
    border-radius: 100%;
    border: 1px solid;
}

.opener-bot {
    top: auto;
    bottom: -5px;
}

.exclusivite {
    transform-origin: top left;
    width: 120%;
    color: white;
    background: #eb2f06;
    position: absolute;
    z-index: 1;
    padding: 4px 12px;
    text-transform: uppercase;
    transform: rotate(15deg);
    bottom: 115px;
    font-size: 8px;
    letter-spacing: 4px;
    font-family: 'Impact';
}

.pack-content h1 {
    position: absolute;
    color: white;
    background-color: #1e272e;
    z-index: 1;
    top: 10px;
    width: calc(100% - 22px);
    text-align: center;
    font-size: 14px;
    font-family: 'Bungee Spice';
    text-transform: uppercase;
    letter-spacing: 1px;
    left: 10px;
    height: 30px;
    line-height: 30px;

    &::before,
    &::after {
        content: "";
        position: absolute;
    }

    &::before {
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 15px 0 15px 10px;
        border-color: transparent transparent transparent #1e272e;
        transform: rotate(0deg);
        right: -10px;
    }

    &::after {
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 15px 10px 15px 0;
        border-color: transparent #1e272e transparent transparent;
        transform: rotate(0deg);
        left: -10px;
    }
}

.pastille {
    font-family: 'Silkscreen';
    width: 80px;
    height: 80px;
    position: absolute;
    background: #1e272e;
    z-index: 2;
    border-radius: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    font-size: 9px;
    text-transform: uppercase;
    right: 10px;
    letter-spacing: 2px;
    bottom: 40px;
    line-height: 1.2;
    width: 100px;
    aspect-ratio: 1;
    clip-path: polygon(100.00% 50.00%, 88.04% 62.36%, 90.45% 79.39%, 73.51% 82.36%, 65.45% 97.55%, 50.00% 90.00%, 34.55% 97.55%, 26.49% 82.36%, 9.55% 79.39%, 11.96% 62.36%, 0.00% 50.00%, 11.96% 37.64%, 9.55% 20.61%, 26.49% 17.64%, 34.55% 2.45%, 50.00% 10.00%, 65.45% 2.45%, 73.51% 17.64%, 90.45% 20.61%, 88.04% 37.64%);

    span {
        width: 100%;
    }

    b {
        font-size: 22px;
        display: block;
    }
}

.pack-content {
    margin-top: 25px;
    width: 200px;
    height: 300px;
    background-color: #fff;
    position: relative;
}

.pack-image {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.side-images {
    width: 230px;
    position: absolute;
    height: 100px;
    background-color: red;
    bottom: -25px;
    left: -20px;
    transform: rotate(15deg);
    clear: both;
    overflow: hidden;
    border-top: 2px solid #ccc;

    .side-image {
        float: left;
        width: calc(100% / 3);
        height: 100%;
        transform: rotate(-15deg);
        border-left: 2px solid #ccc;

        img {
            position: relative;
            top: -15px;
            width: 100%;
            height: 120%;
            object-fit: cover;
        }
    }
}

.btn-reveal-ended {
    text-align: center;
    transition: opacity 1s ease 4s;
    opacity: 0;
    display: flex;
    justify-content: space-around;

    a {
        &:first-child {
            margin-left: 0;
        }
    }
    &.display {
        opacity: 1;
    }
}
