.form-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
}
label {
    display: block;
    margin-bottom: 8px;
}
input[type=text], input[type=number], input[type=password], input[type=email] {
    width: 200px;
    border: 1px solid grey;
    height: 30px;
    border-radius: 2px;
    padding: 0 10px;
}

select {
    width: 200px;
    height: 30px;
}

.label-input-image {
  input {
    display: none;
  }
  .label {
    display: block;
    margin-bottom: 10px;
  }
  .placeholder {
    display: block;
    width: 160px;
    height: 220px;
    border: 1px solid gray;
    border-radius: 2px;
    font-size: 80px;
    text-align: center;
    line-height: 200px;
    cursor: pointer;
  }
  img {
    display: none;
    width: 160px;
    height: 220px;
    object-fit: cover;
    border-radius: 2px;
    border: 1px solid gray;
  }
  &.with-temp {
    .placeholder {
      display: none;
    }
    img {
      display: block;
    }
  }
}

/* CSS */
.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}