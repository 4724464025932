table {
    margin: 0 auto;
    table-layout: auto;
    width: calc(100% - 40px);
    tr {
        border-bottom: 1px solid;
        &.active {
            background-color: rgba(blue, .1);
        }
    }
    td {
        line-height: 40px;
        img {
            height: 40px;
            width: 40px;
            object-fit: cover;
            vertical-align: middle;
        }
        input[type=text] {
            width: 60px;
        }
        input[type=checkbox] {
            -webkit-appearance: auto;
        }
    }
    .probability-input {
        display: none;
    }
    .btn-edit {
        margin-left: 10px;
    }
}

input[type=checkbox] {
    -webkit-appearance: auto;
}

.table-pack {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid #ddd;
    span {
        white-space: nowrap;
        width: 30px;
        text-align: center;
        &.card-name {
            width: 120px;
        }
        &.w50 {
            width: 50px;
        }
        &.rarity {
            font-size: 0;
            &::first-letter {
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }
    &.col-2 .line-card {
        width: 50%;
    }
    &.col-3 .line-card {
        width: calc(100% / 3);
    }
    .line-card {
        width: 100%;
        border-bottom: 1px solid #ddd;
        height: 40px;
        display: flex;
        gap: 0px;
        justify-content: space-between;
        line-height: 40px;
        cursor: pointer;
        &.active {
            background-color: rgba(#3c40c6, .1);
        }
        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
        }
    }
}

.settings {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .filter-cards, .settings-columns {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
    }
    .settings-columns {
        margin-left: 40px;
        background-color: rgba(gray, .2);
        height: 30px;
        padding: 0 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        button {
            border: 0;
            margin-left: 20px;
            border-radius: 4px;
            color: white;
            background-color: black;
            cursor: pointer;
            &.active {
                background-color: #3c40c6;
            }
        }
    }
}

.form-pack {
    width: calc(100% - 40px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    button {
        height: 100%;
    }
    input[type=number] {
        width: 100px;
    }
    input[type=checkbox] {
        margin: 0;
        width: 30px;
        height: 30px;
    }
}

.summary {
    text-align: center;
    span {
        display: inline-block;
        padding: 10px 4px 0;
    }
}

.packs-list {
    width: 100%;
    padding: 0 20px;
    .pack-item {
        display: flex;
        justify-content: space-between;
        text-align: center;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #ddd;
        &.is-editing {
            background-color: rgba(#3c40c6, .1);
        }
        span {
            flex: 1;
        }
    }
}