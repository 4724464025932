@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');

.list-shop {
    width: 800px;
    margin: 0 auto;
    position: relative;
}

.item-shop {
    width: 100%;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 40px;

    .pack {
        top: 0;
        z-index: 1;
        position: absolute;
        left: 50px;
    }

    button {
        height: 48px;
        width: 200px;
        right: 0;
        cursor: pointer;
        text-transform: uppercase;
        bottom: 0;
        background-color: orange;
        font-size: 20px;
        font-family: Anton;
        svg {
            height: 20px;
        }
    }
    $color: orange;
    .shop-actions {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: 5px;
    }
    .btn-open-pack {
        height: 48px;
        width: 48px;
        background-color: orangered;
        display: block;
        border-radius: 6px;
        color: white;
        text-align: center;
        line-height: 44px;
        border-bottom: 4px solid rgba(black, .2);
        transition: all .1s;
        position: relative;
        &:hover {
            border-width: 0;
            line-height: 48px;
        }
        b {
            position: absolute;
            font-size: 6px;
            width: 12px;
            height: 12px;
            background-color: black;
            line-height: 1;
            border-radius: 100%;
            display: block;
            line-height: 12px;
            text-align: center;
            top: 2px;
            right: 2px;
        }
    }
    .possession {
        height: 48px;
        width: 100px;
        background-color: #2f3640;
        bottom: 0;
        right: 202px;
        border-radius: 6px;
        color: white;
        font-size: 8px;
        text-align: center;
        text-transform: uppercase;
        padding: 12px 0;
        display: block;
        p {
            margin-bottom: 4px;
        }
        b {
            font-size: 14px;
        }
    }
    
}


.item-shop-content {
    height: 250px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    h1,
    h2 {
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-left: 250px;
        background-color: #2f3640;
    }

    h1 {
        color: white;
        line-height: 30px;
        font-family: Anton;
        font-size: 12px;
        letter-spacing: 2px;
        svg {
            vertical-align: baseline;
        }
    }

    h2 {
        line-height: 20px;
        color: white;
        font-size: 8px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        svg {
            height: 12px;
        }
    }
}

$animationSpeed: 100s;

// Animation
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-100px * 8))
    }
}

.cards-features {
    width: 800px;
    overflow: hidden;
    background-color: #2f3640;

    .card-feature {
        width: 100px;
        height: 150px;
    }

    .card {
        float: none;
        scale: .5;
        transform-origin: top left;
    }

    .cards-slide {
        animation: scroll $animationSpeed linear infinite;
        width: calc(100px * 16);
        display: flex;
    }
}
.shop-actions {
    position: absolute;
    right: 0;
    bottom: 45px;
}