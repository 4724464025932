
.my-photo-wrap {
    margin: 0 auto;
    display: block;
    width: 100px;
    height: 100px;
    position: relative;

    &.open .list-photos {
        display: block;
    }
    margin-bottom: 20px;
}

.my-photo {
    width: 100px;
    height: 100px;
    background-color: black;
    display: inline-block;
    border-radius: 100%;
    border: 4px solid #1e272e;
    object-fit: cover;
    transition: border .2s;
    cursor: pointer;

    &:hover {
        border-color: orange;
    }

    &.no-photo {
        overflow: hidden;
        position: relative;

        &::before,
        &::after {
            content: "";
            position: absolute;
            background-color: #fff;
            left: 50%;
            border-radius: 100%;
        }

        &::before {
            width: 80px;
            height: 80px;
            margin-left: -40px;
            bottom: -40px;
        }

        &::after {
            width: 30px;
            height: 30px;
            margin-left: -15px;
            bottom: 40px;
        }
    }
}

.list-photos {
    display: none;
    text-align: center;
    white-space: nowrap;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: - 130px;
    background-color: #273c75;
    padding: 10px;
    border-radius: 10px;
    box-shadow: #000 2px 2px 2px;

    .btn-close {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: black;
        color: white;
        border: 0;
        text-align: center;
        line-height: 20px;
        padding: 0;
        cursor: pointer;
        transition: background .2s;

        &:hover {
            background-color: orange;
        }
    }

    img {
        width: 100px;
        height: 100px;
        display: inline-block;
        object-fit: cover;
        border-radius: 100%;
        border: 4px solid #1e272e;
        margin-left: 10px;
        cursor: pointer;
        background-color: #1e272e;
        transition: border .1s;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            border-color: #e1b12c;
        }
    }
}

.user-packs {
    display: flex;
    justify-content: space-between;
    width: 800px;
    margin: 0 auto;
    flex-wrap: wrap;
    .user-pack {
        position: relative;
        margin: 10px;
    }

    .pack {
        margin-bottom: 10px;
    }
    .number-of-pack {
        color: white;
        background-color: orangered;
        width: 50px;
        height: 20px;
        display: block;
        line-height: 18px;
        text-align: center;
        border-radius: 2px;
        font-size: 12px;
        position: absolute;
        top: 10px;
        right: -20px;
        border: 1px solid black;
    }
    .btn-open-pack {
        width: 100%;
        font-family: Anton;
        text-transform: uppercase;
        vertical-align: middle;
        letter-spacing: 1px;
        svg {
            vertical-align: baseline;
        }
    }
}

.user-page {
    display: flex;
    min-width: 800px;
    width: 100%;
    padding: 0 20px;
    font-family: Roboto;
    justify-content: space-around;
    h2 {
        width: 200px;
        margin: 0 auto;
        padding-left: 15px;
        margin-bottom: 10px;
        background-color: #1e272e;
        height: 30px;
        line-height: 30px;
        color: white;
    }
    .user-deck {
        height: 150px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
        .item {
            width: 100px;
            .card {
                transform-origin: top left;
                scale: .5;
                float: none;
                margin: 0 auto;
            }
        }
    }
    .btn {
        height: 30px;
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        svg {
            margin-right: 10px;
        }
    }
    .btn-general {
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
    }
    form {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        input, .btn-general {
            width: 50%;
        }
    }
}
.col-left {
    width: 200px;
    .btn-general {
        width: 200px;
    }
}
.col-right {
    width: 50%;
    min-width: 500px;
    h2 {
        margin: 0 0 10px;
        width: 100%;
    }
}
.form-pseudo {
    width: 200px;
    display: flex;
    justify-content: space-between;
    border: 1px solid gray;
    border-radius: 30px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 20px;
    input {
        height: 30px;
        padding: 0 15px;
        border: 0;
        width: 170px;
        border-radius: 30px;
        font-size: 12px;
    }
    button {
        width: 30px;
        height: 30px;
        border: 0;
        background-color: transparent;
    }
}

.fights-stat {
    border: 1px solid gray;
    width: 200px;
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 20px;
    li {
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid gray;
        &:last-child {
            border: 0;
        }
    }
}

.btn-logout {
    width: 200px;
    color: white;
    background-color: orangered;
    border: 0;
    line-height: 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
    border-top: 0px solid white;
    border-bottom: 4px solid rgba(black, .2);
    transition: all .1s ease;
    text-transform: uppercase;
    font-size: 12px;
    &:hover {
        border-bottom-width: 0px;
        border-top-width: 4px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.btn-open-pack {
    height: 48px;
    width: 48px;
    background-color: orangered;
    display: block;
    border-radius: 6px;
    color: white;
    text-align: center;
    line-height: 44px;
    border-bottom: 4px solid rgba(black, .2);
    transition: all .1s;
    position: relative;
    &:hover {
        border-width: 0;
        line-height: 48px;
    }
    b {
        position: absolute;
        font-size: 6px;
        width: 12px;
        height: 12px;
        background-color: black;
        line-height: 1;
        border-radius: 100%;
        display: block;
        line-height: 12px;
        text-align: center;
        top: 2px;
        right: 2px;
    }
}