.form-new {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .form-column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        padding: 0 20px;
    }
}

.btn-delete-card {
    border: none;
    display: inline-block;
    width: 200px;
    padding: 2px 0;
    border-radius: 2px;
    background-color: #c0392b;
    color: white;
    cursor: pointer;
    &:hover {
        background-color: black;
    }
}